import { store } from '@/store/store';
import { ActividadFisica } from '@/shared/dtos/ActividadFisica';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'ActividadFisicaModule',
    store,
    dynamic: true
})
class ActividadFisicaModule extends VuexModule {
    public ActividadFisicas: ActividadFisica[] = [];
    public ActividadFisica: ActividadFisica = new ActividadFisica();

    @Action({ commit: 'onGetActividadFisicas' })
    public async getActividadFisicas() {
        return await ssmHttpService.get(API.ActividadFisica);
    }

    @Action({ commit: 'onGetActividadFisica' })
    public async getActividadFisica(id: any) {
        return await ssmHttpService.get(API.ActividadFisica + '/' + id);
    }

    @Mutation
    public onGetActividadFisicas(res: ActividadFisica[]) {
        this.ActividadFisicas = res ? res.map((x) => new ActividadFisica(x)) : [];
    }

    @Mutation
    public onGetActividadFisica(res: ActividadFisica) {
        this.ActividadFisica = new ActividadFisica(res);
    }

}
export default getModule(ActividadFisicaModule);