

















































import { Component, Vue, Watch, Prop, PropSync } from "vue-property-decorator";
import { ActividadFisica } from "@/shared/dtos/ActividadFisica";
import ActividadFisicaModule from "@/store/modules/ActividadFisica-module";
import { UtilsString } from "@/utils/utils-string";
@Component({
  components: {
    CompHeader: () => import("./CompHeaderLabel.vue")
  }
})
export default class CompActividadFisica extends Vue {
  @PropSync("actividad", { type: String }) syncedactividad!: string;
  @Prop() index!: number;
  public dialog: boolean = false;
  public Seleccionado: any = {
    text: "",
    value: -1
  };
  public async created() {
    await ActividadFisicaModule.getActividadFisicas();
  }
  public get items() {
    let valores: any[] = [];
    for (let i = 0; i < ActividadFisicaModule.ActividadFisicas.length; i++) {
      const element = ActividadFisicaModule.ActividadFisicas[i];
      valores.push({
        text: element.nombre,
        value: element.id
      });
      if (this.syncedactividad === element.nombre) {
        this.Seleccionado = valores[valores.length - 1];
        this.$emit("validado", { index: this.index, valido: true });
      }
    }
    return valores;
  }
  @Watch("Seleccionado")
  public ChangeHorario() {
    for (let i = 0; i < ActividadFisicaModule.ActividadFisicas.length; i++) {
      const element = ActividadFisicaModule.ActividadFisicas[i];
      if (element.id === this.Seleccionado) {
        this.syncedactividad = element.nombre;
        this.$emit("validado", { index: this.index, valido: true });
      }
    }
  }
  public get ItemSelect(): ActividadFisica {
    for (let i = 0; i < ActividadFisicaModule.ActividadFisicas.length; i++) {
      const element = ActividadFisicaModule.ActividadFisicas[i];
      if (this.syncedactividad === element.nombre) {
        this.$emit("validado", { index: this.index, valido: true });
        return element;
      }
    }

    return new ActividadFisica();
  }

  public get Descripcion() {
    return (
      UtilsString.cortarStringLargo(
        UtilsString.ValueOf(this.ItemSelect.descripcion),
        this.$vuetify.breakpoint.xsOnly ? 300 : 900
      ) + "..."
    );
  }
}
